<template>
  <div class="invoice-header">
    <div class="content">
      <AddInvoice
        @sureRefresh="sureRefresh"
        :id="form.id"
        :ref="form.id"
        :type="'details'"
        v-if="form.id"
      >
        <template>
          <el-button size="small" type="primary" @click="$refs[form.id].show()"
            >编辑发票抬头</el-button
          >
        </template>
      </AddInvoice>
      <AddInvoice @sureRefresh="sureRefresh" ref="add" v-else>
        <template>
          <el-button @click="$refs['add'].show()" size="small" type="primary"
            >新增发票抬头</el-button
          >
        </template>
      </AddInvoice>
      <div v-if="form.id">
        <div class="form-div">
          <el-form :disabled="true" label-width="110px" ref="ruleForm">
            <el-form-item label="发票类型">
              <el-radio label="PLAIN_VAT_INVOICE" v-model="form.invoiceType"
                >增值税普通发票</el-radio
              >
              <el-radio label="SPECIAL_VAT_INVOICE" v-model="form.invoiceType"
                >增值税专用发票</el-radio
              >
            </el-form-item>
            <el-form-item label="抬头类型">
              <el-radio label="ENTERPRISE" v-model="form.titleType">企业单位</el-radio>
              <el-radio label="PERSONAL" v-model="form.titleType" v-if="form.invoiceType === 'PLAIN_VAT_INVOICE'">个人/非企业单位</el-radio>
            </el-form-item>
            <el-form-item label="发票抬头" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="税号" v-if="form.titleType === 'ENTERPRISE'">
              <el-input v-model="form.taxNo"></el-input>
            </el-form-item>
            <el-form-item label="开户银行" v-if="form.titleType === 'ENTERPRISE'">
              <el-input v-model.trim="form.bankName"></el-input>
            </el-form-item>
            <el-form-item label="开户行账号" v-if="form.titleType === 'ENTERPRISE'">
              <el-input v-model.trim="form.bankAccount"></el-input>
            </el-form-item>
            <el-form-item label="企业电话" v-if="form.titleType === 'ENTERPRISE'">
              <el-input v-model.trim="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="企业地址" v-if="form.titleType === 'ENTERPRISE'">
              <el-input v-model.trim="form.address"></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model.trim="form.contactName"></el-input>
            </el-form-item>
            <el-form-item label="联系人邮箱" prop="email">
              <el-input v-model.trim="form.email"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机号" prop="contactPhone">
              <el-input v-model.trim="form.contactPhone"></el-input>
            </el-form-item>
            <el-form-item label="收件地址" prop="value">
              <AreaCascader class="area-width" v-model="form.value" />
            </el-form-item>
            <el-form-item label="详细地址" prop="contactAddress">
              <el-input v-model.trim="form.contactAddress"></el-input>
            </el-form-item>
            <el-form-item label="开票备注">
              <el-input v-model="description"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="price">
          <p>开票金额</p>
          <span>￥{{ price }}</span>
        </div>
        <div class="sure-div">
          <el-button :loading="saveLoading" @click="sure" size="small" type="primary"
            >确定</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddInvoice from '@/components/AddInvoice';
import AreaCascader from '@/components/AreaCascader';
import { getMyDefault, invoiceCreate } from '@/api/invoice';
export default {
  name: 'invoice-header',
  components: {
    AddInvoice,
    AreaCascader,
  },
  data() {
    return {
      form: {
        id: '',
        invoiceType: 'PLAIN_VAT_INVOICE',
        titleType: 'ENTERPRISE',
        title: '',
        taxNo: '',
        bankName: '',
        bankAccount: '',
        address: '',
        mobile: '',
        email: '',
        value: null,
        contactAddress: '',
      },
      ids: [],
      saveLoading: false,
    };
  },
  created() {
    this.getMyDefault();
    this.price = sessionStorage.getItem('invoicePrice');
    this.ids = JSON.parse(sessionStorage.getItem('invoiceIds'));
    this.description = sessionStorage.getItem('invoiceDescription');
  },
  methods: {
    async getMyDefault() {
      const { data: res } = await getMyDefault();
      if (res) {
        if (res.contactAddressVO && res.contactAddressVO.provinceId) {
          res.value = [
            res.contactAddressVO.provinceId,
            res.contactAddressVO.cityId,
            res.contactAddressVO.zoneId,
          ];
          res.contactAddress = res.contactAddressVO.addressDetail;
        }else{
          res.value = [];
          res.contactAddress = '';
        }
        this.form = res;
      }
    },
    sureRefresh(data) {
      this.getMyDefault();
    },
    sure() {
      const data = {
        invoiceTitleId: this.form.id,
        description: this.description || '',
        izAllInvoice: this.ids.length > 0 ? '0' : '1',
        orderBrandType: this.$route.query.orderBrandType
      };
      this.ids.length > 0 ? data.orderIds = this.ids : '';
      this.saveLoading = true;
      invoiceCreate(data)
        .then((res) => {
          if (res.code === '0') {
              this.$message.success('操作成功');
              this.$router.push({ path: '/syoung/invoice/invoice-record',
            });
          }
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-header {
  .content {
    background: #fff;
    .form-div {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .el-form {
        width: 50%;
      }
      .btn-div {
        margin-left: 20px;
      }
    }
    .price {
      p {
        width: 98px;
        text-align: right;
        display: inline-block;
      }
      span {
        color: red;
      }
    }
    .sure-div {
      width: 50%;
      text-align: center;
    }
  }
  .el-cascader {
    width: 100%;
  }
}
</style>